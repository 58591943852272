import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import './payment-method-slider.scss'

interface Props {
  itemsLength: number
  scrollWrapperRef: React.RefObject<HTMLDivElement>
  itemWidth: number
  itemGap: number
}

export const PaymentMethodSlider: React.FC<Props> = ({ itemsLength, scrollWrapperRef, itemWidth, itemGap }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [visibleCount, setVisibleCount] = useState(2)

  const handleScroll = () => {
    if (scrollWrapperRef.current) {
      const scrollLeft = scrollWrapperRef.current.scrollLeft
      const currentIndex = Math.round(scrollLeft / itemWidth)

      setActiveIndex(currentIndex)
    }
  }

  useEffect(() => {
    const scrollContainer = scrollWrapperRef.current
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (scrollContainer) scrollContainer.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (scrollWrapperRef.current) {
        const containerWidth = scrollWrapperRef.current.offsetWidth

        setVisibleCount(Math.floor(containerWidth / (itemWidth + itemGap)) || 2)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [itemWidth, itemGap])

  const handleDotClick = (index) => {
    setActiveIndex(index)
    const scrollLeft = index * (itemWidth + itemGap)

    if (scrollWrapperRef.current) {
      scrollWrapperRef.current.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      })
    }
  }

  const totalDots = Math.ceil(itemsLength - visibleCount)

  return (
    <div className='payment-method-slider'>
      {Array.from({ length: totalDots }).map((_, index) => (
        <div
          key={index}
          className={classNames('payment-method-slider--dot', {
            'payment-method-slider--dot-active': activeIndex === index,
          })}
          onClick={() => handleDotClick(index)}
        />
      ))}
    </div>
  )
}
